<template>
  <account-balance
    :client-type="usedClientType"
    :client-id="actingClientId"
    :is-own="isOwn"/>
</template>

<script>
  import AccountBalance from './AccountBalance'
  import {mapGetters} from 'vuex'

  export default {
    components: {
      AccountBalance
    },

    computed: {
      ...mapGetters ('auth', [
        'actingClientType',
        'clientType',
        'isImpersonated',
        'actingClientId'
      ]),

      usedClientType () {
        return this.isImpersonated
          ? this.actingClientType
          : this.clientType
      },

      isOwn () {
        const accountTypes = ['Member', 'Partner', 'Internal', 'Agent']

        return this.isImpersonated
          ? !accountTypes.includes (this.actingClientType)
          : accountTypes.includes (this.clientType)
      }
    }
  }
</script>
