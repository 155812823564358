<template>
  <base-dialog
    v-model="dialogState"
    mw0
    persistent>
    <v-card>
      <v-card-title
        v-t="'general.unsavedChanges'"
        class="text-h5"/>

      <v-card-text v-t="'general.unsavedChangesDesc'"/>

      <v-card-actions>
        <v-spacer/>

        <v-btn
          v-t="'general.no'"
          @click="submit (false)"/>
        <v-btn
          v-t="'general.yes'"
          color="primary"
          @click="submit (true)"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    name: 'UnsavedChangesDialog',

    components: {
      BaseDialog
    },

    computed: {
      ...mapState ('create', [
        'unsavedChangesDialog',
        'unsavedChangesCB'
      ]),

      dialogState: {
        get () {
          return this.unsavedChangesDialog
        },
        set (newValue) {
          this.setUnsavedChangesDialog (newValue)
        }
      }
    },

    methods: {
      ...mapMutations ('create', [
        'setUnsavedChangesDialog'
      ]),

      submit (data) {
        if (this.unsavedChangesCB) {
          this.unsavedChangesCB (data)
        }
      }
    }
  }
</script>
