<template>
  <v-snackbar
    v-model="isSnackbarShown"
    top
    :color="snackbarMessageClass"
    :timeout="5000">
    <span style="white-space: pre">{{ snackbarMessage }}</span>
    <v-btn
      icon
      small
      color="accent"
      @click.native="isSnackbarShown = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    name: 'NotificationSnackbar',

    data: () => ({
      isSnackbarShown: false
    }),

    computed: {
      ...mapState ({
        snackbarMessage: state => state.notification.message,
        messageType: state => state.notification.messageType,
        hasMessage: state => state.notification.hasMessage
      }),

      snackbarMessageClass () {
        return this.messageType
      }
    },

    watch: {
      hasMessage (newValue) {
        if (newValue) {
          this.isSnackbarShown = true
        }
      },

      isSnackbarShown (showSnackbar) {
        if (!showSnackbar) {
          this.resetMessage ()
        }
      }
    },

    methods: {
      ...mapMutations ('notification', ['resetMessage'])
    }
  }
</script>
