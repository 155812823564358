<messages>["./UserActions"]</messages>

<template>
  <div v-show="isActive">
    <v-menu v-if="isAuthenticated">
      <template #activator="{ on }">
        <div
          v-ripple
          class="pa-2"
          v-on="on">
          <v-tooltip bottom>
            <template #activator="{ on: tooltip }">
              <v-row
                align="center"
                v-on="tooltip">
                <v-col class="shrink pe-0">
                  <v-icon>person_outline</v-icon>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="text-truncate px-0">
                  <span
                    class="ml-1"
                    v-text="displayName"/>
                </v-col>
                <v-col class="shrink ps-0">
                  <v-icon>keyboard_arrow_down</v-icon>
                </v-col>
              </v-row>
            </template>
            {{ displayName }}
          </v-tooltip>
        </div>
      </template>
      <v-list>
        <v-list-item @click="userProfile">
          <v-list-item-title v-t="'user.profile.title'"/>
        </v-list-item>
        <v-list-item
          v-if="isImpersonated"
          @click="onRecover">
          <v-list-item-title v-text="$t ('recoverTo', userDisplayName)"/>
        </v-list-item>
        <v-list-item @click="openedEventList = !openedEventList">
          <v-list-item-title v-text="$t ('general.label.sessionEvents')"/>
        </v-list-item>
        <v-list-item @click="onLogout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      v-else
      :to="{name: 'login'}">
      <v-icon>perm_identity</v-icon>
      <span>Login</span>
    </v-btn>
    <base-dialog
      v-model="isLoginRefreshDialogActive"
      fullscreen
      transition="dialog-bottom-transition"
      :overlay="false"
      scrollable>
      <v-card>
        <v-toolbar
          dark
          class="primary flex-grow-0">
          <v-toolbar-title v-t="'loginRefresh.title'"/>
        </v-toolbar>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'loginRefresh.headline'"
              class="text-h5"/>
            <p v-t="'loginRefresh.text'"/>
            <p>
              <router-link
                v-t="'loginRefresh.loginText'"
                :to="{name: 'login'}"
                target="_blank"
                @click.native="isLoginRefreshDialogActive = false"/>
            </p>
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.ok'"
            color="primary"
            @click="authRefresh"/>
        </v-card-actions>
      </v-card>
    </base-dialog>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  import BaseDialog from '@/app/core/components/BaseDialog'

  // interval (in sec) to update authentication TTL
  const TTL_UPDATE_INTERVAL = 5

  // ID of timer to be used to be able to clear the timer
  // created on component mount
  let timerId

  export default {
    name: 'UserActions',

    components: {
      BaseDialog
    },

    data () {
      return {
        isLoginRefreshDialogActive: false
      }
    },

    computed: {
      ...mapState ('auth', [
        'isActive',
        'isAuthenticated',
        'operationMode',
        'ttl',
        'lastApiAccessTime'
      ]),
      ...mapGetters ('auth', [
        'userDisplayName',
        'clientName',
        'clientId',
        'shouldRefresh',
        'isImpersonated',
        'isAutoRefreshAllowed'
      ]),

      ...mapState ('notification', ['events', 'showEventMenu']),

      openedEventList: {
        get () {
          return this.showEventMenu
        },
        set (newVal) {
          this.setShowEventMenu (newVal)
        }
      },

      displayName () {
        function addNonEmpty (arr, what) {
          if (what) {
            arr.push (what)
          }
        }

        const formatClient = (clientName, clientId) => {
          const clientNotSet = this.$t ('clientNotSet')
          const res = [clientName || clientNotSet]
          const clientIdStr = clientId ? `ID ${clientId}` : ''

          addNonEmpty (res, clientIdStr)

          return `(${res.join (', ')})`
        }

        function formatDisplayName (displayName, clientName, clientId) {
          const res = []
          addNonEmpty (res, displayName)
          addNonEmpty (res, formatClient (clientName, clientId))

          return res.join (' ')
        }

        const {userDisplayName, effectiveUserDisplayName} = this.userDisplayName
        const {clientName, effectiveClientName} = this.clientName
        const {clientId, effectiveClientId} = this.clientId

        let displayName =
          formatDisplayName (userDisplayName, clientName, clientId)

        if (this.isImpersonated) {
          const effectiveDisplayName = formatDisplayName (
            effectiveUserDisplayName, effectiveClientName, effectiveClientId)

          displayName = this.$t ('displayName', {
            displayName,
            effectiveDisplayName
          })
        }

        return displayName
      }
    },

    watch: {
      isActive (val) {
        if (!val) {
          this.isLoginRefreshDialogActive = false
          // this.$router.go (this.$router.currentRoute)
          // `window.location.reload` is used to prevent problems in
          // MS Edge browser
          window.location.reload ()
        }
      },

      // on TTL change
      ttl () {
        this.checkLogin ()
      },

      shouldRefresh (val) {
        if (val) {
          this.prepareAuthRefresh ()
        }
      }
    },

    mounted () {
      timerId = window.setInterval (
        // cyclic enforce session TTL update if the user is "active" (JWT exist)
        () => { this.isActive && this.updateTtl () },
        TTL_UPDATE_INTERVAL * 1000)
    },

    beforeUnmount () {
      // cleanup the TTL updater timer before component destruction to prevent memory leaks
      clearInterval (timerId)
    },

    methods: {
      ...mapMutations ({
        displayErrorMessage: 'notification/setError',
        updateTtl: 'auth/updateTtl',
        setShowEventMenu: 'notification/setShowEventMenu'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData',
        recover: 'auth/recover',
        logout: 'auth/logout'
      }),

      checkLogin () {
        if (this.ttl <= 0) {
          if (this.isActive) {
            this.logout ()
          }
        } else {
          // check if auto refresh allowed on last TTL tick
          if (this.ttl <= TTL_UPDATE_INTERVAL && this.isAutoRefreshAllowed) {
            this.authRefresh ()
          }
        }
      },

      prepareAuthRefresh () {
        if (this.isAutoRefreshAllowed) {
          this.authRefresh ()
        } else {
          this.isLoginRefreshDialogActive = true
        }
      },

      authRefresh () {
        return this.fetchData ({
          op: 'auth/refresh',
          cb: () => {
            console.log ('AUTH refreshed')
          },
          cbError: () => {
            this.displayErrorMessage ('loginRefresh.fail')
          },
          cbFinal: () => {
            this.isLoginRefreshDialogActive = false
          }
        })
      },

      userProfile () {
        this.$router.push ({name: 'user.profile'})
      },

      onRecover () {
        this.recover ()
        this.$router.push ({name: 'dashboard'})
      },

      onLogout () {
        this.logout ()
        console.log ('logged out')
      }
    }
  }
</script>
