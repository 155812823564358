<messages>["./Event"]</messages>

<template>
  <v-navigation-drawer
    v-model="opened"
    right
    width="600px"
    absolute
    temporary>
    <v-btn class="ml-2 mt-2" icon fab @click="opened = !opened">
      <v-icon>chevron_right</v-icon>
    </v-btn>
    <v-tooltip bottom>
      <span>{{ $t('view.download') }}</span>
      <template #activator="{on}">
        <v-btn class="ml-2 mt-2" icon fab v-on="on" @click="downloadEventList">
          <v-icon>file_download</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <div class="mx-3">
      <v-checkbox v-model="showSuccess" :label="$t('view.showSuccess')"/>
      <v-checkbox v-model="showErrors" :label="$t('view.showErrors')"/>
    </div>
    <div v-if="noEvents" class="mx-3">
      <span>
        {{ $t ('view.noEvents') }}
      </span>
    </div>
    <template v-else>
      <div class="mx-3">
        <v-alert v-for="e in filteredEvents" :key="e.id" :type="e.type">
          <event-item :event="e"/>
        </v-alert>
      </div>
    </template>

    <!-- <div class="mx-3">
      <v-btn class="ml-2 mt-2" @click="addSuccess">
        success
      </v-btn>

      <v-btn class="ml-2 mt-2" @click="addError">
        error
      </v-btn>
    </div> -->
  </v-navigation-drawer>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'

  import EventItem from './EventItem'

  export default {
    name: 'EventList',

    components: {EventItem},

    data () {
      return {
        showErrors: false,
        showSuccess: true
      }
    },

    computed: {
      ...mapState ('notification', ['events', 'showEventMenu']),

      opened: {
        get () {
          return this.showEventMenu
        },
        set (newVal) {
          this.setShowEventMenu (newVal)
        }
      },

      noEvents () {
        return !this.filteredEvents.length
      },

      filteredEvents () {
        return this.events?.filter ((e) => {
          if (!this.showErrors && e.type === 'error') return false
          if (!this.showSuccess && e.type === 'success') return false
          return true
        })
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      ...mapMutations ('notification', ['setShowEventMenu']),

      addSuccess () {
        this.addEvent ({type: 'success', message: 'this is a success message', objects: [{name: 'test1'}]})
      },

      addError () {
        this.addEvent ({type: 'error', message: 'this is an error message', objects: null})
      },

      downloadEventList () {
        this.downloadFile (`events-${this.formatDate (new Date ())}.csv`, this.events?.reduce ((acc, i) => {
          return acc + '\n' + this.eventItemToString (i)
        }, 'type,createdAt,message,objects'))
      },

      downloadFile (filename, text) {
        const element = document.createElement ('a')
        element.setAttribute ('href', 'data:csv/plain;charset=utf-8,' + encodeURIComponent (text))
        element.setAttribute ('download', filename)

        element.style.display = 'none'
        document.body.appendChild (element)

        element.click ()

        document.body.removeChild (element)
      },

      eventItemToString (i) {
        return `${i.type},${this.formatDate (i.createdAt)},${i.message.replaceAll (',', '+')},${i.objects?.map ((i) => {
          const content = '(name: ' + i.name + (i.link ? ' link: ' + window.location.origin + '/' + this.$router.resolve (i.link).href : '') + ')'
          return content
        }).join ('-') || ''}`
      }
    }
  }
</script>
