<template>
  <v-menu>
    <template #activator="{ on: menu }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="{ ...tooltip, ...menu }">
            <v-icon>
              language
            </v-icon>
          </v-btn>
        </template>
        <span>Language</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item
        v-for="locale in locales"
        :key="locale"
        @click="switchLocale(locale)">
        <v-list-item-action>
          <v-icon v-if="$i18n.locale === locale">
            check
          </v-icon>
        </v-list-item-action>
        <v-list-item-title v-t="`locales.${locale}`"/>
        <v-list-item-action/>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'
  import {locales} from '@/app/i18n/locales'

  export default {
    name: 'LanguageSwitch',

    data () {
      return {
        locales
      }
    },

    computed: {
      ...mapState ({
        appLocale: state => state.locale.appLocale
      })
    },

    /**
     * set the application locale, stored in the store, when the
     * language switcher is placed on the page
     */
    created () {
      this.$i18n.locale = this.appLocale
    },

    methods: {
      ...mapMutations ('locale', [
        'setAppLocale'
      ]),

      /**
       * switch the locale, used by vue-i18n module and store the
       * location key into the application store
       *
       * @param {string} localeKey    key of the locale to be used for i18n
       *                              in the application
       */
      switchLocale (localeKey) {
        this.$i18n.locale = localeKey
        this.setAppLocale (localeKey)
      }
    }
  }
</script>
