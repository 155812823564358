<template>
  <v-list>
    <template v-for="item in nav">
      <v-list-item
        v-if="!item.children"
        :key="item.url"
        :to="item.url"
        active-class="default-class accent lighten-5 primary--text"
        class="sideNavItem">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t (item.label) }}</v-list-item-title>
      </v-list-item>
      <v-list-group
        v-else
        :key="item.url"
        :prepend-icon="item.icon "
        :value="expanded[item.url]">
        <template #activator>
          <v-list-item-title>{{ $t (item.label) }}</v-list-item-title>
        </template>
        <v-list-item
          v-for="subItem in item.children"
          :key="subItem.url"
          :to="subItem.url"
          :value="$route.path === subItem.url"
          link
          active-class="default-class accent lighten-5 primary--text"
          class="sideNavSubItem">
          <v-list-item-icon/>
          <v-list-item-title>{{ $t (subItem.label) }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
  import createUser from '@/app/core/permissions/user'

  export default {
    name: 'SideNav',

    data () {
      return {}
    },

    computed: {
      nav () {
        const navItems = []

        this.$router.options.routes[4].children.forEach (route => {
          const navItem = this.routeToNavItem (route)
          if (navItem) {
            navItems.push (navItem)
          }
        })

        return navItems
      },

      expanded () {
        return this.nav.reduce ((acc, item) => {
          return {
            ...acc,
            [item.url]: this.$route.path.indexOf (item.url) === 0
          }
        }, {})
      }
    },

    methods: {
      routeToNavItem (route) {
        let navItem = null

        if (route.meta && !route.meta.hideInMenu && this.isMenuAccessible (route.meta)) {
          navItem = {
            name: route.name,
            url: route.path,
            label: route.meta.label,
            icon: route.meta.icon,
            constraint: route.meta.constraint
          }

          if (route.children) {
            const children = []
            route.children.forEach (child => {
              const c = this.routeToNavItem (child)
              if (c != null) {
                children.push (c)
              }
            })

            if (children.length) {
              navItem.children = children
            } else {
              // if the menu item has children, but they all are not accessible,
              // then this item should not be displayed
              navItem = null
            }
          }
        }

        return navItem
      },

      isMenuAccessible (item) {
        // all menus are accessible by default
        let isMenuAccessible = true

        const constraint = item.constraint || null

        if (constraint) {
          const constraints = constraint instanceof Array
            ? constraint
            : [constraint]

          const user = createUser (this.$store)

          isMenuAccessible = constraints.every (constraint =>
            constraint.isSatisfied (user))
        }

        return isMenuAccessible
      }
    }
  }
</script>
