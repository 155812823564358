<template>
  <v-app>
    <v-navigation-drawer
      v-if="!$route.meta.hideSidebar"
      v-model="drawer"
      app
      enable-resize-watcher>
      <div class="d-flex justify-end pa-2">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click.stop="drawer = false">
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </template>
          <span v-t="'general.menu.hide.tooltip'"/>
        </v-tooltip>
      </div>
      <side-nav/>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-tooltip
        v-if="!drawer && !$route.meta.hideSidebar"
        bottom>
        <template #activator="{ on }">
          <v-app-bar-nav-icon
            class="mr-2"
            v-on="on"
            @click.stop="drawer = true"/>
        </template>
        <span v-t="'general.menu.show.tooltip'"/>
      </v-tooltip>

      <router-link
        v-if="$vuetify.breakpoint.smAndUp"
        to="/"
        class="logo mr-3">
        <img
          class="logoImg"
          src="@/assets/logo.svg"
          alt="GatewayNG">
      </router-link>

      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">
        {{ $t (routeTitleKey) }}
      </v-toolbar-title>

      <v-spacer/>
      <quick-domain-search v-if="isAuthenticated"/>
      <own-account-balance v-if="showAccountBalance"/>
      <div v-if="$vuetify.breakpoint.smAndUp && !isLoadingMeta" class="mx-1">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon :color="flavourColor">
                {{ flavourIcon }}
              </v-icon>
            </div>
          </template>
          <span>{{ flavourText }}</span>
        </v-tooltip>
      </div>
      <language-switch class="mr-2"/>
      <user-actions/>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>
      </v-container>
    </v-main>

    <v-footer app>
      <v-container
        fluid
        class="py-2">
        COREhub SRLU &copy; {{ currentYear }}. All Rights Reserved.
      </v-container>
    </v-footer>

    <notification-snackbar/>
    <unsaved-changes-dialog/>
    <event-list/>
  </v-app>
</template>

<script>
  import {mapGetters, mapMutations, mapState, mapActions} from 'vuex'

  import OwnAccountBalance from './core/components/App/OwnAccountBalance'
  import LanguageSwitch from './core/components/App/LanguageSwitch'
  import NotificationSnackbar from './core/components/App/NotificationSnackbar'
  import SideNav from './core/components/App/SideNav'
  import QuickDomainSearch from './core/components/Search/QuickDomainSearch'
  import UserActions from './core/components/App/UserActions'
  import EventList from './core/components/EventList'
  import unsavedChangesDialog from './core/components/UnsavedChangesDialog'

  const flavours = {
    devel: {
      icon: 'code',
      color: 'error',
      text: 'flavour.devel',
      short: 'flavour.develShort'
    },
    acc: {
      icon: 'build',
      color: 'grey',
      text: 'flavour.acc',
      short: 'flavour.accShort'
    },
    eval: {
      icon: 'bug_report',
      color: 'warning',
      text: 'flavour.eval',
      short: 'flavour.evalShort'
    },
    prod: {
      icon: 'check_circle',
      color: 'success',
      text: 'flavour.prod',
      short: 'flavour.prodShort'
    }

  }

  export default {
    name: 'App',

    components: {
      OwnAccountBalance,
      LanguageSwitch,
      NotificationSnackbar,
      SideNav,
      UserActions,
      unsavedChangesDialog,
      QuickDomainSearch,
      EventList
    },

    data: () => ({
      drawer: null,
      currentYear: new Date ().getUTCFullYear (),
      isLoadingMeta: false,
      flavour: null
    }),

    computed: {
      ...mapGetters ('auth', [
        'actingClientType',
        'clientType',
        'hasAnyOfPermissions'
      ]),

      // mix the state into computed with object spread operator
      ...mapState ({
        routeTitleKey: s => s.router.routeTitleKey,
        isAuthenticated: s => s.auth.isAuthenticated
      }),

      showAccountBalance () {
        const accountTypes = ['Member', 'Partner', 'Internal', 'Agent']

        return this.isAuthenticated && this.hasAnyOfPermissions (['DoAccounting']) &&
          (accountTypes.includes (this.actingClientType) ||
            accountTypes.includes (this.clientType))
      },

      flavourIcon () {
        return flavours[this.flavour]
          ? flavours[this.flavour].icon
          : 'help'
      },

      flavourText () {
        return flavours[this.flavour]
          ? this.$t (flavours[this.flavour].text)
          : this.flavour
      },

      flavourTextShort () {
        return flavours[this.flavour]
          ? this.$t (flavours[this.flavour].short)
          : this.flavour
      },

      flavourColor () {
        return flavours[this.flavour]
          ? flavours[this.flavour].color
          : 'grey'
      }
    },

    watch: {
      drawer (newValue) {
        this.setDrawerVisible (newValue)
      }
    },

    mounted () {
      this.setDrawerVisible (this.drawer)
      window.addEventListener ('resize', this.reportWindowHeight)
      this.reportWindowHeight ()
      this.loadMetaData ()
    },

    methods: {
      ...mapMutations ('layout', ['setDrawerVisible', 'setWindowHeight']),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      reportWindowHeight () {
        this.$nextTick (function () {
          this.setWindowHeight (document.documentElement.clientHeight)
        })
      },

      loadMetaData () {
        this.isLoadingMeta = true

        this.fetchData ({
          op: 'getMetaData',
          cb: data => {
            this.flavour = data.flavour
          },
          cbFinal: () => {
            this.isLoadingMeta = false
          }
        })
      }
    }
  }
</script>

<style scoped>
  .logo {
    max-width: 160px;
  }

  .logoImg {
    display: block;
    width: 100%;
  }
</style>
