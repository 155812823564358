<template>
  <div>
    <span class="text-decoration-underline">{{ formatDate(event.createdAt) }}</span>
    <p>{{ event.message }}</p>
    <template v-if="event.objects && event.objects.length">
      <ul>
        <li v-for="o in event.objects" :key="o.name">
          <event-object-link :object="o"/>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
  import EventObjectLink from './EventObjectLink.vue'
  export default {
    components: {
      EventObjectLink
    },
    props: {
      event: {
        type: Object,
        required: true
      }
    }
  }
</script>
