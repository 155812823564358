<template>
  <span>
    <router-link v-if="object.link" :to="object.link">{{ object.name }}</router-link>
    <template v-else>{{ object.name }}</template>
  </span>
</template>

<script>
  export default {
    props: {
      object: {
        type: Object,
        required: true
      }
    }
  }
</script>
