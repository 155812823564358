<messages>["./Search"]</messages>

<template>
  <v-row>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-col class="pa-0 pt-4 ps-4">
        <v-slide-x-transition>
          <form v-if="!hidden" @submit.prevent="submit">
            <v-text-field
              ref="field"
              v-model.trim="term"
              :loading="isLoading"
              :label="$t ('quicksearch.title')"
              clearable
              type="text"/>
          </form>
        </v-slide-x-transition>
      </v-col>
      <v-col class="shrink ps-0">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              :loading="isLoading"
              icon
              v-on="on"
              @click="btnClick">
              <v-icon>
                search
              </v-icon>
            </v-btn>
          </template>
          <span v-t="'quicksearch.title'"/>
        </v-tooltip>
      </v-col>
    </template>
    <v-menu
      v-else
      v-model="menu"
      :close-on-content-click="false"
      x-offset
      :nudge-width="200">
      <v-card>
        <v-card-text>
          <form @submit.prevent="submit">
            <v-text-field
              ref="menuField"
              v-model.trim="term"
              :loading="isLoading"
              :label="$t ('quicksearch.title')"
              clearable
              type="text"/>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.close'"
            text
            @click="menu=false"/>
          <v-btn
            v-t="'general.button.submit'"
            color="primary"
            :loading="isLoading"
            @click="submit"/>
        </v-card-actions>
      </v-card>
      <template #activator="{ on: outer }">
        <v-tooltip bottom>
          <template #activator="{ on: inner }">
            <v-btn
              :loading="isLoading"
              icon
              v-on="{ ...outer, ...inner }"
              @click="menu=true">
              <v-icon>
                search
              </v-icon>
            </v-btn>
          </template>
          <span v-t="'quicksearch.title'"/>
        </v-tooltip>
      </template>
    </v-menu>
  </v-row>
</template>

<script>
  import {mapActions} from 'vuex'

  import {byDomainName}
    from '@/app/pages/Domain/components/DomainFilter'

  export default {
    data () {
      return {
        term: '',
        isLoading: false,
        hidden: true,
        menu: false
      }
    },

    watch: {
      menu (val) {
        this.hidden = !val
        if (val) {
          this.$nextTick (() => {
            if (this.$refs.menuField) {
              this.$refs.menuField.focus ()
            }
          })
        }
      },
      hidden (val) {
        this.menu = !val
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        if (!this.term || this.term === '') return
        const name = this.term
        this.isLoading = false

        this.fetchData ({
          op: 'domain/exists',
          params: {
            name
          },
          cb: data => {
            this.$router.push ({
              name: `domain.view${data.deleted ? '.version' : ''}`,
              params: data.deleted ? {vid: data.id} : {name}
            })
          },
          cbError: () => {
            this.$router.push ({
              name: 'domain.search',
              query: {
                filter: JSON.stringify (byDomainName (name))
              }
            })
          },
          cbFinal: () => {
            this.isLoading = false
            this.term = ''
            this.hidden = true
            this.menu = false
          }
        })
      },

      btnClick () {
        if (this.hidden) {
          this.hidden = false

          this.$nextTick (() => {
            if (this.$refs.field) {
              this.$refs.field.focus ()
            }
          })
        } else if (!this.hidden && this.term && this.term !== '') {
          this.submit ()
        } else {
          this.hidden = true
        }
      }
    }
  }
</script>
